@use "@/styles/mixins/media-queries" as *;

.matches {
  --match-card-list-row-gap: 1rem;
  grid-row-gap: var(--match-card-list-row-gap);
  grid-template-columns: repeat(auto-fill, minmax(19rem, 1fr));
  margin-top: 1.25rem;
  width: 100%;

  @include tablet-portrait-and-up {
    --match-card-list-row-gap: 1.5rem;
  }

  @include desktop-and-up {
    --match-card-list-row-gap: 2.5rem;
  }
}

.link {
  display: flex;
  margin-top: 1.125rem;
  min-height: 2rem;
}
