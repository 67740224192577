@use "@/styles/mixins/ellipsis" as *;

.container {
  --outcome-color: var(--label-text-color);
  display: grid;
  gap: 0.5rem;
  max-width: 10rem;
  container-name: container;
  container-type: inline-size;
  text-decoration: none;
}

.win {
  --outcome-color: var(--system-green);
}

.loss {
  --outcome-color: var(--system-red);
}

.draw {
  --outcome-color: var(--label-text-color);
}

.outcomeWrapper {
  display: flex;
  place-content: center;
  position: absolute;
  top: -0.5rem;
  left: 0;
  right: 0;
}

.outcome {
  background: var(--outcome-color);
  color: var(--primary-background-color);
  text-transform: uppercase;
  min-width: 1.5rem;
  padding: 0 6px;
  width: fit-content;
  border-radius: var(--rounded-corner);
  text-align: center;
}

.outcomeFull{
  display: none;
}

.match {
  box-sizing: border-box;
  position: relative;
  display: grid;
  row-gap: 0.5rem;
  grid-auto-columns: 1fr max-content 1fr;
  place-content: center;
  padding: 1.25rem 0.875rem;
  border: 1px solid var(--outcome-color);
  text-align: center;

  border-radius: var(--rounded-corner);
}

.matchHiddenHome {
  grid-template-areas: "home-score line away-score" "away away away" ;
}

.matchHiddenAway {
  grid-template-areas: "home-score line away-score" "home home home" ;
}

.homeScore {
  grid-area: home-score;
}

.awayScore {
  grid-area: away-score;
}

.line {
  grid-area: line;
}

.homeTeam {
  grid-area: home;
  justify-self: center;
}

.awayTeam {
  grid-area: away;
  justify-self: center;
}

.hide {
  display: none;
}

.date {
  text-align: center;
  color: var(--label-text-color);
}

@container container (min-width: 6.25rem) {
  .match {
    grid-template-areas: "home-score line away-score" "home empty away";
    grid-auto-columns: 1.75rem 1.25rem 1.75rem;
  }

  .hide {
    display: block;
  }

  .outcome{
    min-width: 3.75rem;
  }

  .outcomeFull {
    display: inline-flex;
  }

  .outcomeShort {
    display: none;
  }
}

