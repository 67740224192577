@use "@/styles/mixins/media-queries" as *;
@use "@/styles/mixins/ellipsis" as *;
@use "@/styles/mixins/max-root-width";

.standings {
  --standing-row-side-padding: 0.625rem;

  margin: 0 auto;
}

.standings__tableWrapper {
  @include max-root-width.limit;
  margin-top: 1rem;

  & + & {
    margin-top: 1.25rem;
  }
}

.standings__tableHeaderText {
  grid-column: span 3;
}

.standings__row {
  &.standings__rowGrid {
    padding-bottom: 0.625rem;
  }

  .standings__rowGrid {
    padding: 0.875em 0;
  }

  &:not(:first-child) + & {
    .standings__rowGrid {
      border-top: 1px solid var(--elevated-background-color);
    }
  }
}

.standings__rowLink {
  border-radius: var(--rounded-corner);
  transition: background-color 250ms ease-in-out;

  @media (hover: hover) {
    &:hover {
      background-color: var(--elevated-background-color);
    }
  }
}

.standings__rowGrid {
  display: grid;
  grid-template-columns: 2ch 1.75em 1fr repeat(3, 4ch);
  color: inherit;
  text-decoration: inherit;
}

.standings__cell {
  display: flex;
  place-items: center;
  justify-self: end;

}


.standings__cellTextDimmed {
  color: var(--label-text-color);
}


.standings__cellIcon {
  display: flex;
  place-items: center;
  place-content: center;
  justify-self: center;
}

.standings__cellLargeScreen {
  display: none;
}

.standings__icon {
  width: 0.75rem;
}

.standings__team {
  display: flex;
  place-items: center;
  height: 2rem;
  @include ellipsis;

  .standings__teamLogo {
    //overwriting entity-logo padding
    padding: 0.3rem;
  }
}

.standings__teamLogo {
  width: 2rem;
  height: 2rem;
  margin-inline-end: 0.625rem;
}

.standings__teamName {
  @include ellipsis;
}

.standings__iconNoChanges {
  width: 0.25rem;
  height: 0.25rem;
  border: 0.125em solid var(--interaction-unactive-state-color);
  border-radius: 50%;
  box-sizing: content-box;
}

@include tablet-portrait-and-up {
  .standings__cellLargeScreen {
    display: flex;
  }

  .standings__rowGrid {
    grid-template-columns: 2ch 1.75em 1fr repeat(6, 4ch);
  }
}

.standings__missingRows {
  display: grid;
  place-items: center;
  gap: 0.1875rem;
  height: 1.5rem;
  border-radius: var(--rounded-corner);
  background-color: var(--elevated-background-color);
}

.standings__missingRowsIcon {
  height: 0.25rem;
  color: var(--divider-color);
}

.standings__bottomLinkIcon {
  opacity: 0.7;
}