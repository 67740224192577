.container {
  display: grid;
  gap: 1.5rem;
}

.list {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: repeat(5, 1fr);
  gap: 0.875rem;
}