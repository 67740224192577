@use "@/styles/mixins/media-queries" as *;
@use "@/styles/mixins/ellipsis" as *;
@use "@/styles/mixins/max-root-width" as *;

.container{
  @include limit;
}

.label {
  --query-param-selector-max-width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: var(--query-param-selector-max-width);
  @include tablet-portrait-and-up {
    --query-param-selector-max-width: 21rem;
  }
  @include tablet-landscape-and-up {
    --query-param-selector-max-width: 18.75rem;
  }
  @include desktop-and-up {
    --query-param-selector-max-width: 25rem;
  }
}

.select {
  appearance: none;
  border: 1px solid var(--divider-color);
  padding: 1rem 2.25rem 1rem 1.25rem;
  width: 100%;
  @include ellipsis();
  border-radius: var(--rounded-corner);
  background-color: var(--elevated-background-color);
  color: var(--primary-text-color);
}

@media (hover: hover) {
  .select:hover {
    cursor: pointer;
  }
}

.openIcon {
  position: absolute;
  inset-inline-end: 1.25rem;
  width: 0.75rem;
  fill: none;
  stroke: var(--label-text-color);
  stroke-width: 2;
}
